import { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import { useUpdateTokens } from "utils/hooks";
import { currentRoleIsUser } from "utils/helper";
import { Header } from "components/Common/Header";
import { Button } from "components/Common/Button";
import { SearchBarContainer } from "components/Common/SearchBarContainer";
import { DeleteModal } from "components/Common/DeleteModal";
import AddPagesModal from "components/Sections/AddSections/AddSectionModal";
import { PaginationContainer } from "components/Common/PaginationContainer";
import { EmptyPage } from "components/Common/EmptyPage";
import {
  NoOffersSVG,
  PencilIconSVG,
  TrashboxIconSVG,
} from "assets/icons/index";
import CustomTable from "components/CustomTable/CustomTable";
import { sectionType } from "types/offer";
import { createColumnHelper } from "@tanstack/table-core";
import { ActionIcon, Divider, Switch } from "@mantine/core";
import styles from "../Offers/Offers.module.scss";
import { getStorageItem } from "services/storage";
import { useNotification } from "context";
import {
  useDeleteSectionMutation,
  useGetSectionListQuery,
  useUpdateSectionMutation,
} from "hooks/Admin/sectionAPI";

type SectionAPIOptionsType = {
  page: number;
  size: string;
};

const Sections = () => {
  const [sectionAPIOptions, setSectionAPIOptions] =
    useState<SectionAPIOptionsType>({
      page: 1,
      size: "10",
    });

  const { data: sectionList } = useGetSectionListQuery({
    page: sectionAPIOptions?.page,
    size: sectionAPIOptions?.size,
  });
  const { setText } = useNotification();
  const [isAddSectionModalVisible, setAddSectionModalVisible] = useState(false);
  const [isRemoveOfferModalVisible, setRemoveOfferModalVisible] =
    useState(false);
  const [editSectionModal, setEditSectionModal] = useState({
    isOpen: false,
    offer: "",
  });
  const [toBeDeletedSection, setToBeDeletedSection] = useState("");
  const [offerVisible, setOfferVisible] = useState(true);
  useUpdateTokens();

  const onPageChange = (value: number) =>
    setSectionAPIOptions((prev) => ({ ...prev, page: value }));
  const onRecordsSizeChange = (value: string) =>
    setSectionAPIOptions((prev) => ({ ...prev, size: value }));

  const checkAdmin = () => {
    if (
      getStorageItem("role") === "PROTEAN_SUPER_ADMIN" ||
      getStorageItem("role") === "BUYER_APP_SUPER_ADMIN"
    ) {
      setOfferVisible(true);
    } else {
      setOfferVisible(false);
    }
  };
  useEffect(() => {
    checkAdmin();
  }, []);

  useEffect(() => {
    if (toBeDeletedSection.length > 0) {
      setRemoveOfferModalVisible(true);
    }
  }, [toBeDeletedSection]);
  const [
    deleteSection, // This is the mutation trigger
  ] = useDeleteSectionMutation();

  const [
    updateSection, // This is the mutation trigger
  ] = useUpdateSectionMutation();

  async function handleRemoveOffer() {
    const response = await deleteSection(toBeDeletedSection);
    if (response.data.success) {
      setRemoveOfferModalVisible(false);
      setText("Section Removed");
    }
  }

  const onChangeStatus =
    (data: any, status: any, action: string) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      let payload = data;
      if (action === "active") {
        payload = {
          ...payload,
          img: payload.img._id,
          isActive: !status,
        };
      } else if (action === "visible") {
        payload = {
          ...payload,
          img: payload.img._id,
          isVisible: !status,
        };
      }
      if (payload?.subCategory?.category) {
        payload = {
          ...payload,
          subCategory: payload.subCategory.category,
        };
      }

      if (payload?.category) {
        payload = {
          ...payload,
          category: payload.category._id,
        };
      }
      delete payload._id;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.__v;
      updateSection({ id: data._id, payload });
    };

  const onDeleteSection = (_id: string) => (event: MouseEvent<HTMLElement>) => {
    setToBeDeletedSection(_id);
  };

  const columnHelper = createColumnHelper<sectionType>();

  const columns = [
    columnHelper.accessor("_id", {
      header: "Section ID",
      enableSorting: false,
      cell: (info: any) => (
        <div className={styles.ellipsis}>{info.getValue()}</div>
      ),
    }),

    columnHelper.accessor("name", {
      header: "Title",
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    }),

    columnHelper.accessor("displayFormat", {
      header: "Format",
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    }),

    {
      id: "action",
      header: "Action",
      enableSorting: false,
      cell: (info: any) => (
        <div className={styles.action} style={{ display: "flex" }}>
          <ActionIcon
            disabled={!offerVisible}
            onClick={() => {
              setEditSectionModal({
                isOpen: true,
                offer: info.row.original,
              });
            }}
          >
            <PencilIconSVG />
          </ActionIcon>
          <ActionIcon
            onClick={onDeleteSection(info.row.original._id)}
            disabled={!offerVisible}
          >
            <TrashboxIconSVG />
          </ActionIcon>
        </div>
      ),
      size: 80,
    },
  ];

  const columnVisibility = {
    action: !currentRoleIsUser(),
  };

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">Sections</div>
        <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 mx-80">
          <SearchBarContainer
            item={["Section", "Sections"]}
            itemsCount={sectionList?.data?.count}
            listingCount={sectionList?.data?.sections?.length}
          />
          {
            <Button
              type="filled-primary"
              text="Add Sections"
              onClick={() => setAddSectionModalVisible(true)}
              classNames={{
                root: "add-admin-btn brd-10",
                label: "txt-14 txtw-5",
              }}
            />
          }
        </div>
        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        {!!sectionList?.data?.sections?.length ? (
          <>
            <section className="min-h-70 bd-gray  flex-col bg-white mt-5 mb-5 mx-80">
              <CustomTable
                columns={columns}
                data={sectionList?.data?.sections}
                className={styles.table}
                columnVisibility={columnVisibility}
              />
            </section>
            <PaginationContainer
              page={sectionAPIOptions.page}
              setPage={onPageChange}
              size={sectionAPIOptions.size}
              setSize={onRecordsSizeChange}
              totalEntries={sectionList?.data?.count}
            />
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Sections" />
        )}
      </main>

      <AddPagesModal
        editOfferModal={editSectionModal}
        isAddBannerModalVisible={isAddSectionModalVisible}
        setAddBannerModalVisible={setAddSectionModalVisible}
        setEditOfferModal={setEditSectionModal}
      />
      <DeleteModal
        isDeleteModalOpen={isRemoveOfferModalVisible}
        closeDeleteModal={() => {
          setRemoveOfferModalVisible(false);
          setToBeDeletedSection("");
        }}
        data={{
          text: "Section",
        }}
        onConfirm={handleRemoveOffer}
      />
    </div>
  );
};

export default Sections;
