import { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import { useUpdateTokens } from "utils/hooks";
import { currentRoleIsUser } from "utils/helper";
import { Header } from "components/Common/Header";
import { Button } from "components/Common/Button";
import { SearchBarContainer } from "components/Common/SearchBarContainer";
import { DeleteModal } from "components/Common/DeleteModal";
import AddBannerModal from "components/Banners/AddBanner/AddBannerModal";
import { PaginationContainer } from "components/Common/PaginationContainer";
import { EmptyPage } from "components/Common/EmptyPage";
import {
  NoOffersSVG,
  PencilIconSVG,
  TrashboxIconSVG,
} from "assets/icons/index";
import {
  useDeleteBannerMutation,
  useGetBannerListQuery,
  useUpdateBannerMutation,
} from "hooks/Admin/bannersAPI";
import CustomTable from "components/CustomTable/CustomTable";
import { bannerType } from "types/offer";
import { createColumnHelper } from "@tanstack/table-core";
import { ActionIcon, Divider, Switch } from "@mantine/core";
import styles from "../Offers/Offers.module.scss";
import defaultOfferBanner from "assets/images/default_offer_banner.png";
import { getStorageItem } from "services/storage";
import { useNotification } from "context";

type OffersAPIOptionsType = {
  page: number;
  size: string;
};

const Banners = () => {
  const [offersAPIOptions, setOfferAPIOptions] = useState<OffersAPIOptionsType>(
    {
      page: 1,
      size: "10",
    }
  );

  const { data: offersList } = useGetBannerListQuery({
    page: offersAPIOptions?.page,
    size: offersAPIOptions?.size,
  });
  const { setText } = useNotification();
  const [isAddBannerModalVisible, setAddBannerModalVisible] = useState(false);
  const [isRemoveOfferModalVisible, setRemoveOfferModalVisible] =
    useState(false);
  const [editOfferModal, setEditOfferModal] = useState({
    isOpen: false,
    offer: "",
  });
  const [toBeDeletedOffer, setToBeDeletedOffer] = useState("");
  const [offerVisible, setOfferVisible] = useState(true);
  useUpdateTokens();

  const onPageChange = (value: number) =>
    setOfferAPIOptions((prev) => ({ ...prev, page: value }));
  const onRecordsSizeChange = (value: string) =>
    setOfferAPIOptions((prev) => ({ ...prev, size: value }));

  const checkAdmin = () => {
    if (
      getStorageItem("role") === "PROTEAN_SUPER_ADMIN" ||
      getStorageItem("role") === "BUYER_APP_SUPER_ADMIN"
    ) {
      setOfferVisible(true);
    } else {
      setOfferVisible(false);
    }
  };
  useEffect(() => {
    checkAdmin();
  }, []);

  useEffect(() => {
    if (toBeDeletedOffer.length > 0) {
      setRemoveOfferModalVisible(true);
    }
  }, [toBeDeletedOffer]);
  const [
    deleteOffer, // This is the mutation trigger
  ] = useDeleteBannerMutation();

  const [
    updateOffer, // This is the mutation trigger
  ] = useUpdateBannerMutation();

  async function handleRemoveOffer() {
    const response = await deleteOffer(toBeDeletedOffer);
    if (response.data.success) {
      setRemoveOfferModalVisible(false);
      setText("Banner Removed");
    }
  }

  const onChangeStatus =
    (data: any, status: any, action: string) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      let payload = data;
      if (action === "active") {
        payload = {
          ...payload,
          img: payload.img._id,
          isActive: !status,
        };
      } else if (action === "visible") {
        payload = {
          ...payload,
          img: payload.img._id,
          isVisible: !status,
        };
      }
      delete payload._id;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.__v;
      updateOffer({ id: data._id, payload });
    };

  const onDeleteOffer = (_id: string) => (event: MouseEvent<HTMLElement>) => {
    setToBeDeletedOffer(_id);
  };

  const columnHelper = createColumnHelper<bannerType>();

  const columns = [
    columnHelper.accessor("_id", {
      header: "Banner ID",
      enableSorting: false,
      cell: (info: any) => (
        <div className={styles.ellipsis}>{info.getValue()}</div>
      ),
    }),

    columnHelper.accessor("title", {
      header: "Title",
      enableSorting: false,
      cell: (info: any) => info.getValue(),
    }),

    columnHelper.accessor("img", {
      header: "Image Cover",
      enableSorting: false,
      cell: (info: any) => (
        <img
          src={info.row.original.img?.link || defaultOfferBanner}
          alt="Offer Cover"
          style={{
            height: "50px",
            width: "50px",
            margin: "auto",
            objectFit: "contain",
          }}
        />
      ),
    }),

    columnHelper.accessor("isVisible", {
      header: "Visible",
      enableSorting: false,
      cell: (info: any) => (
        <Switch
          disabled={!offerVisible}
          defaultChecked={info.getValue()}
          onChange={onChangeStatus(
            info.row.original,
            info.getValue(),
            "visible"
          )}
          color="primary"
          classNames={{ track: "cursor-ptr" }}
        />
      ),
    }),
    {
      id: "action",
      header: "Action",
      enableSorting: false,
      cell: (info: any) => (
        <div className={styles.action} style={{ display: "flex" }}>
          <ActionIcon
            disabled={!offerVisible}
            onClick={() => {
              setEditOfferModal({
                isOpen: true,
                offer: info.row.original,
              });
            }}
          >
            <PencilIconSVG />
          </ActionIcon>
          <ActionIcon
            onClick={onDeleteOffer(info.row.original._id)}
            disabled={!offerVisible}
          >
            <TrashboxIconSVG />
          </ActionIcon>
        </div>
      ),
      size: 80,
    },
  ];

  const columnVisibility = {
    action: !currentRoleIsUser(),
  };

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">Banners</div>
        <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 mx-80">
          <SearchBarContainer
            item={["Offer", "Offers"]}
            itemsCount={offersList?.data?.count}
            listingCount={offersList?.data?.banners?.length}
          />
          {offerVisible ? (
            <Button
              type="filled-primary"
              text="Add Banner"
              onClick={() => setAddBannerModalVisible(true)}
              classNames={{
                root: "add-admin-btn brd-10",
                label: "txt-14 txtw-5",
              }}
            />
          ) : null}
        </div>
        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        {!!offersList?.data?.banners?.length ? (
          <>
            <section className="min-h-70 bd-gray  flex-col bg-white mt-5 mb-5 mx-80">
              <CustomTable
                columns={columns}
                data={offersList?.data?.banners}
                className={styles.table}
                columnVisibility={columnVisibility}
              />
            </section>
            <PaginationContainer
              page={offersAPIOptions.page}
              setPage={onPageChange}
              size={offersAPIOptions.size}
              setSize={onRecordsSizeChange}
              totalEntries={offersList?.data?.count}
            />
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Banners" />
        )}
      </main>

      <AddBannerModal
        editOfferModal={editOfferModal}
        isAddBannerModalVisible={isAddBannerModalVisible}
        setAddBannerModalVisible={setAddBannerModalVisible}
        setEditOfferModal={setEditOfferModal}
      />
      <DeleteModal
        isDeleteModalOpen={isRemoveOfferModalVisible}
        closeDeleteModal={() => {
          setRemoveOfferModalVisible(false);
          setToBeDeletedOffer("");
        }}
        data={{
          text: "Banner",
        }}
        onConfirm={handleRemoveOffer}
      />
    </div>
  );
};

export default Banners;
