import { Divider } from "@mantine/core"
import { Header } from "components/Common/Header"
import { SearchBarContainer } from "components/Common/SearchBarContainer"
import CustomTable from "components/CustomTable/CustomTable"
import { useGetMobilityRidesListQuery } from "hooks/Mobility/orderAPI"
import { useState } from "react"
import { mobilityColumns } from "./Columns"
import styles from "pages/OrderManagement/OrderManagement.module.scss"
import { PaginationContainer } from "components/Common/PaginationContainer"
import { EmptyPage } from "components/Common/EmptyPage"
import { NoOffersSVG } from "assets/icons"
import { rideStatusMap } from "utils/helper"
import MobilityRideDetails from "components/OrderDetails/MobilityRideDetails"
import { RidesAPIOptionsType } from "types/rides"

export default function MobilityRidesManagement() {
  const [ridesAPIOptions, setRidesAPIOptions] = useState<RidesAPIOptionsType>({
    page: 1,
    size: "10",
  })

  const { data: ordersList } = useGetMobilityRidesListQuery({
    page: ridesAPIOptions?.page,
    size: ridesAPIOptions?.size,
  })

  const [showRideDetails, setShowRideDetails] = useState<boolean>(false)
  const [rideId, setRideID] = useState<string>("")

  const paginationData = ordersList?.result?.pagination
  const currentPage = ordersList?.result?.pagination?.currentPage

  const tableData =
    ordersList?.result && ordersList?.result?.rides?.length > 0
      ? ordersList.result.rides.map((ride: any) => {
          const {
            distanceTraveled,
            ridePay,
            rider,
            rideStatus,
            startAddress,
            endAddress,
            _id,
          } = ride

          return {
            distanceTraveled: distanceTraveled,
            riderName: rider?.name,
            from: startAddress?.address,
            orderId: _id,
            status: rideStatusMap(rideStatus).toLowerCase(),
            to: endAddress?.address,
            totalAmount: ridePay,
          }
        })
      : null

  const onPageChange = (value: number) =>
    setRidesAPIOptions((prev) => ({ ...prev, page: value }))

  const onRecordsSizeChange = (value: string) =>
    setRidesAPIOptions((prev) => ({ ...prev, size: value }))

  const handleShowRide = (_id: string) => {
    setRideID(_id)
    setShowRideDetails(true)
  }

  return showRideDetails && rideId ? (
    <MobilityRideDetails
      rideId={rideId}
      goBack={() => setShowRideDetails(false)}
    />
  ) : (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">Ride Management</div>
        <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 mx-80">
          <SearchBarContainer
            item={["Ride", "Rides"]}
            itemsCount={paginationData?.totalCount}
            listingCount={tableData?.length}
          />
        </div>
        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        {!!tableData?.length ? (
          <>
            <section className="min-h-70 bd-gray  flex-col bg-white mt-5 mb-5 mx-80">
              <CustomTable
                columns={mobilityColumns(handleShowRide, currentPage)}
                data={tableData}
                className={styles["mobility-table"]}
              />
            </section>
            <PaginationContainer
              page={ridesAPIOptions.page}
              setPage={onPageChange}
              size={String(ridesAPIOptions.size)}
              setSize={onRecordsSizeChange}
              totalEntries={paginationData?.totalCount}
            />
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Rides" />
        )}
      </main>
    </div>
  )
}
