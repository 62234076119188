import { MouseEvent, useCallback, useState } from "react";
import { Header } from "components/Common/Header";
import { PaginationContainer } from "components/Common/PaginationContainer";
import { EmptyPage } from "components/Common/EmptyPage";
import { SearchBarContainer } from "components/Common/SearchBarContainer";
import {
  NoOffersSVG,
  PencilIconSVG,
  TrashboxIconSVG,
} from "assets/icons/index";
import styles from "./CategoryManagement.module.scss";
import "react-phone-input-2/lib/style.css";
import {
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoryListQuery,
  useUpdateCategoryMutation,
} from "hooks/Admin/categoryAPI";
import { ActionIcon, Divider, Switch } from "@mantine/core";
import { createColumnHelper } from "@tanstack/react-table";
import { categoryType } from "types/category";
import CustomTable from "components/CustomTable/CustomTable";
import CategoryEditModal from "../../components/Categories/CategoryEdit/CategoryEditModal";
import defaultCategory from "assets/images/default_category.png";
import { useUpdateTokens } from "utils/hooks";
import SubCategory from "components/Categories/SubCategory";
import { currentRoleIsUser } from "utils/helper";
import OptionsBarContainer from "components/Common/OptionsBarContainer/OptionsBarContainer";
import { Button } from "components/Common/Button";
import { DeleteModal } from "components/Common/DeleteModal";
import { getStorageItem } from "services/storage";
import { useNotification } from "context";

type CategoryAPIOptionsType = {
  page: number;
  size: string;
  sort: string;
};

const adminType = getStorageItem("adminType");

const columnHelper = createColumnHelper<categoryType>();

const CategoryManagement = () => {
  const [categoryAPIOptions, setCategoryAPIOptions] =
    useState<CategoryAPIOptionsType>({
      page: 1,
      size: "10",
      sort: "",
    });

  useUpdateTokens();

  const { data: categoryList } = useGetCategoryListQuery({
    page: categoryAPIOptions?.page,
    size: categoryAPIOptions?.size,
    sort: categoryAPIOptions?.sort,
  });

  const [
    updateCategory, // This is the mutation trigger
    // { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useUpdateCategoryMutation();

  const [addCategory] = useAddCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();
  const { setText } = useNotification();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isAddModal, setIsAddModal] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [toBeDeletedCategory, setToBeDeletedCategory] = useState<any>(null);
  const [isRemoveCategoryModalVisible, setRemoveCategoryModalVisible] =
    useState<boolean>(false);

  const columns = [
    columnHelper.accessor("categoryName", {
      enableSorting: false,
      header: ({ table }) => (
        <>
          <span>Category</span>
        </>
      ),
      cell: (info) => {
        const ImageLink = `${info?.row?.original?.image?.link}`;
        return (
          <>
            <div
              className={styles.categoryContainer}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                className={styles.categoryImage}
                alt="category-icon"
                src={ImageLink || defaultCategory}
                style={{ marginRight: "20px" }}
              />
              <span className="mr-10">{info.getValue()}</span>
            </div>
          </>
        );
      },
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor("displayName", {
      header: "Display Name",
      enableSorting: false,
      cell: (info) => (
        <span>
          {info?.getValue()?.length > 0 ? (
            info.getValue()
          ) : (
            <div
              className="flex align-center cursor-ptr"
              onClick={onEditCategory(info.row.original._id)}
            >
              <span className="txt-12 clr-primary txtw-6">
                Add Display Name
              </span>
            </div>
          )}
        </span>
      ),
    }),
    columnHelper.accessor("visible", {
      header: "Visible",
      enableSorting: false,
      cell: (info: any) => (
        <Switch
          defaultChecked={info.getValue()}
          onChange={() => {
            onSave({
              _id: info.row.original._id,
              visible: !info.getValue(),
            });
          }}
          color="primary"
          classNames={{ track: "cursor-ptr" }}
          // checked={info.getValue()}
          // color="primary"
          // classNames={{ track: "cursor-ptr" }}
        />
      ),
    }),
    {
      id: "action",
      header: "Action",
      enableSorting: false,
      cell: (info: any) => (
        <div className={styles.action}>
          {currentRoleIsUser() ? null : (
            <>
              <ActionIcon onClick={onEditCategory(info.row.original)}>
                <PencilIconSVG />
              </ActionIcon>
              <ActionIcon onClick={onDeleteCategory(info.row.original)}>
                <TrashboxIconSVG />
              </ActionIcon>
            </>
          )}
        </div>
      ),
      size: 80,
    },
  ];

  const onDeleteCategory = (data: any) => (event: MouseEvent<HTMLElement>) => {
    setRemoveCategoryModalVisible(true);
    setToBeDeletedCategory(data._id);
  };

  async function handleRemoveCategory() {
    const response = await deleteCategory({ id: toBeDeletedCategory });
    if (response?.data?.success) {
      setRemoveCategoryModalVisible(false);
      setToBeDeletedCategory(null);
      setText("Category Removed");
    }
  }

  const onEditCategory = (data: any) => (event: MouseEvent<HTMLElement>) => {
    setShowModal(true);
    setSelectedData(data);
  };

  const onClose = () => {
    setShowModal(false);
    setSelectedData(null);
    setIsAddModal(false);
  };

  const onSave = async ({
    _id,
    domain,
    categoryName,
    displayName,
    visible,
    media,
  }: {
    _id?: string;
    categoryName?: string;
    domain?: string;
    displayName?: string;
    visible: boolean;
    media?: string;
  }) => {
    if (isAddModal) {
      let payload = {
        domain: domain,
        categoryName: categoryName,
        visible: visible,
        displayName: displayName,
        image: media,
        isBusiness: adminType === "e-commerce-b2b" ? true : false,
      };
      const response = await addCategory({ _id, payload });
      if (response?.data?.success) {
        setShowModal(false);
        setSelectedData(null);
        setIsAddModal(false);
        setText("Category Added");
      } else {
        const errorMessage =
          response?.error?.data?.error ??
          "Something went wrong. Please try again.";
        setText(errorMessage, "error");
      }
    } else {
      let payload = {
        // _id: _id,
        visible: visible,
        displayName: displayName,
        image: media,
        isBusiness: adminType === "e-commerce-b2b" ? true : false,
      };
      const response = await updateCategory({ _id, payload });
      if (response?.data?.success) {
        setShowModal(false);
        setSelectedData(null);
        setText("Category Updated");
      }
    }
  };

  const onPageChange = (value: number) => {
    setCategoryAPIOptions((prev) => ({ ...prev, page: value }));
  };

  const onRecordsSizeChange = (value: string) =>
    setCategoryAPIOptions((prev) => ({ ...prev, size: value }));

  const renderRowSubComponent = useCallback(
    ({ row }: any) => <SubCategory categoryId={row.original._id} />,
    []
  );
  const onSortChange = (value: string) => {
    setCategoryAPIOptions((prev) => ({
      ...prev,
      sort: value,
    }));
  };
  const columnVisibility = {
    action: !currentRoleIsUser(),
  };

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">Category Management</div>
        <div className="pg-subheader h-40 flex justify-stretch align-center mb-30 mx-80">
          <SearchBarContainer
            item={["Category", "Categories"]}
            itemsCount={categoryList?.data?.count}
            listingCount={categoryList?.data?.data?.length}
          />
          <div className="flex justify-end">
            <OptionsBarContainer
              selectedTab="categories"
              isFilter={false}
              isSearch={false}
              onSortChange={onSortChange}
            />
            <Button
              type="filled-primary"
              text="Create New Category"
              onClick={() => {
                setSelectedData(null);
                setShowModal(true);
                setIsAddModal(true);
              }}
              classNames={{
                root: "pg-subheader-btn brd-10",
                label: "txt-14 txtw-5",
              }}
            />
          </div>
        </div>
        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        {!!categoryList?.data?.data?.length ? (
          <>
            <section className="min-h-70 bd-gray  flex-col bg-white mt-5 mb-5 mx-80">
              <CustomTable
                columns={columns}
                expandable={true}
                data={categoryList?.data?.data}
                className={styles.table}
                renderRowSubComponent={renderRowSubComponent}
                getRowCanExpand={() => true}
                columnVisibility={columnVisibility}
              />
            </section>
            <PaginationContainer
              page={categoryAPIOptions.page}
              setPage={onPageChange}
              size={categoryAPIOptions.size}
              setSize={onRecordsSizeChange}
              totalEntries={categoryList?.data?.count}
            />
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Categories" />
        )}
      </main>
      {showModal && (
        <CategoryEditModal
          editModalVisible={showModal}
          data={selectedData}
          isAddModal={isAddModal}
          onSave={onSave}
          onClose={onClose}
        />
      )}
      <DeleteModal
        isDeleteModalOpen={isRemoveCategoryModalVisible}
        closeDeleteModal={() => setRemoveCategoryModalVisible(false)}
        data={{
          text: "category",
        }}
        onConfirm={handleRemoveCategory}
      />
    </div>
  );
};
export default CategoryManagement;
