import { Header } from "components/Common/Header";
import CustomTable from "components/CustomTable/CustomTable";
import { useGetOrderListByCustomerIDQuery } from "hooks/Admin/customerAPI";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { CustomerAPIOptionsType } from "types/customerManagement";
import { padDecimals } from "utils/helper";
import { ecommerceColumns } from "./Columns";
import { PaginationContainer } from "components/Common/PaginationContainer";
import { EmptyPage } from "components/Common/EmptyPage";
import { NoOffersSVG } from "assets/icons";
import styles from "pages/Customer/Customer.module.scss";
import OptionsBarContainer from "components/Common/OptionsBarContainer/OptionsBarContainer";
import { SearchBarContainer } from "components/Common/SearchBarContainer";

export default function ECommerceCustomers() {
  const params = useParams();

  const [customerAPIOptions, setCustomerAPIOptions] =
    useState<CustomerAPIOptionsType>({
      page: 1,
      size: "10",
      params: "",
      sort: "",
    });
  const { data: tableList } = useGetOrderListByCustomerIDQuery({
    id: params?.id,
    page: customerAPIOptions?.page,
    size: customerAPIOptions?.size?.toString(),
    params: customerAPIOptions?.params,
    sort: customerAPIOptions?.sort,
  });

  const finalTableData = tableList?.data?.details;
  const { totalCount } = tableList?.data ?? 0;

  const headerDetails = [
    tableList?.data?.totalCount && {
      text: "Total Orders",
      value: tableList?.data?.totalCount,
    },
    tableList?.data?.totalAmount && {
      text: "Total Amount",
      value: "₹" + padDecimals(tableList?.data?.totalAmount),
    },
  ].filter((item) => item);

  const onPageChange = (value: number) =>
    setCustomerAPIOptions((prev) => ({ ...prev, page: value }));

  const onRecordsSizeChange = (value: string) =>
    setCustomerAPIOptions((prev) => ({ ...prev, size: value }));

  const onFiltersChange = (value: string) => {
    setCustomerAPIOptions((prev) => ({
      ...prev,
      params: value,
    }));
  };
  const onSortChange = (value: string) => {
    setCustomerAPIOptions((prev) => ({
      ...prev,
      sort: value,
    }));
  };

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main className="pg-main bg-white flex-1">
        <div className="section-header">
          <span className="txtw-6 txt-30 ">
            {tableList?.data?.name
              ? tableList?.data?.name
              : finalTableData?.[0]?.customerName}
          </span>
          <div className="flex w-full flex-wrap justify-stretch gap-20 my-30">
            {headerDetails.map(({ text, value }) => (
              <div
                className="flex flex-1 bd-full brd-8 bg-white px-24 py-20"
                key={text}
              >
                <div className="flex flex-col">
                  <span className="txt-14 clr-grey txtw-5">{text}</span>
                  <span className="txtw-6 txt-20 mt-8 mb-16">{value}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col mb-20">
            <span className="txtw-6 txt-18">Orders</span>
            <span className="txtw-4 txt-14 clr-grey">
              <SearchBarContainer
                item={["Order", "Orders"]}
                itemsCount={tableList?.data?.totalCount}
                listingCount={tableList?.data?.count}
              />
              {/* {`${totalCount} order${totalCount == 1 ? "" : "s"}`} */}
            </span>
          </div>
        </div>
        <div className="mx-80 pb-20 flex justify-end">
          <OptionsBarContainer
            selectedTab="customers"
            isSearch={false}
            onFiltersChange={onFiltersChange}
            onSortChange={onSortChange}
          />
        </div>

        {!!finalTableData?.length ? (
          <>
            <section className="min-h-70 bd-gray  flex-col bg-white mt-5 mb-5 mx-80">
              <CustomTable
                columns={ecommerceColumns}
                data={finalTableData}
                className={styles["ecom-table"]}
              />
            </section>
            <PaginationContainer
              page={customerAPIOptions.page}
              setPage={onPageChange}
              size={String(customerAPIOptions.size)}
              setSize={onRecordsSizeChange}
              totalEntries={totalCount}
            />
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Customer details" />
        )}
      </main>
    </div>
  );
}
